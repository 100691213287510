import React, {Component} from 'react';
import { setup } from '../../../config/'
import './style.css';

class ImageModule extends Component {

    render() {
        var { module, onLoadCall } = this.props;    
        var renderUrl = ( module.url ) ? module.url.replace( setup.originWP, setup.originCDN ) : false;
        var renderImge = ( renderUrl ) ? <img src={ renderUrl } alt={ renderUrl } /> : '';
        return(
            <figure className={ 'image ' + module.addclass } onLoad={ onLoadCall }>
                { renderImge } 
            </figure>
        )
    }
    
}
export default ImageModule;
