import React, {Component} from 'react';
import _ from 'lodash'
import { ScreenView } from '../pages';
import { getScreensInfo, getWebSocketTokenDisplay } from '../../API'
import { getDisplaySocket, getRemoteSocket, onLoadScreenSendMessage, setVideoIsReadySendmessage, setVideoIsEndSendmessage, setVideoBufferSatusSendmessage, forceReloadScreenSendMessage } from '../../WS';
import { setup } from '../../config'

import './App.css';

class App extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            WSError             : false,
            APIerror            : false,
            APIerrorInfo        : false, 
            iPadReady           : false,
            kioskMode           : false,
            kioskMax            : 0,
            kioskSlide          : 0,
            viewLayoutKeyS1     : 0,
            viewLayoutKeyS2     : 1,
            currentScreen       : 1,
            currentView         : 1,
            viewScreen1         : 'active',
            viewScreen2         : 'unactive',
            isFetching          : false,
            isReady             : true,
            screensArray        : [{'layout':'loader','modules':[{'template' :'load'}]}],
            currentWPID         : false,
            WSToken             : false,
            webSocket           : false,
            webSocketR          : false,
            WSReady             : false,
            isHidden            : false,
            isPlayBack          : true,
            isVideoReady        : false,
            isVideoEnd          : false,
            mustReload          : false,
            countDown           : 10,
            dspConnectInfo      : false,
            isVideoBuffering    : true,
            slideState      : {
                slider1Pos      : 0,
                slider2Pos      : 0,
                slider3Pos      : 0,
                slider4Pos      : 0,
                expandSlide1    : false,
                expandSlide2    : false,
                expandSlide3    : false,
                expandSlide4    : false,    
            }
        };

        this.getData                = this.getData.bind( this );
        this.onLoadScreen           = this.onLoadScreen.bind( this );
        this.setIpadStatus          = this.setIpadStatus.bind( this );
        this.setVideoIsReady        = this.setVideoIsReady.bind( this );
        this.setVideoIsEnd          = this.setVideoIsEnd.bind( this );
        this.setVideoBufferSatus    = this.setVideoBufferSatus.bind( this );
        this.refreshPage            = this.refreshPage.bind( this );

        this.renderVersion          = this.renderVersion.bind( this );
        this.refreshPWASource       = this.refreshPWASource.bind( this );

        this.loadCount          = 0;
        this.loadableCount      = 1;
    }
    
    refreshPage( mode ) {
        this.setState({ mustReload : mode });
    }
    
    getDataModeRemote( response, wpid ) {
        clearInterval( this.state.kioskMode ); 
        this.setState({ 
            screensArray    : response.data.screens,
            currentWPID     : wpid,
            viewLayoutKeyS1 : 0,
            viewLayoutKeyS2 : 0,
            kioskMode       : false,
            kioskMax        : 0
        }, () => { this.setLayout( 0 ) } );
    }
    
    setLayoutOnKiosk( ck1, ck2, slide ) {
        if ( ck1 !== ck2 ) {
            this.setLayout( slide );
        }
    }

    setVideoIsReady() {
        this.setState({
            isVideoReady : true,
        }, () => { setVideoIsReadySendmessage( this, this.state.isVideoReady ) } )
    }
    
    setVideoIsEnd() {
        this.setState({
            isVideoEnd : true,
        }, () => { setVideoIsEndSendmessage( this, this.state.isVideoEnd ) } )
    }

    setVideoBufferSatus( status ) {
        this.setState({
            isVideoBuffering : status,
        }, () => { setVideoBufferSatusSendmessage( this, this.state.isVideoBuffering ) } )
    }
    
    setLayoutKiosk() {
        let prevSlide = this.state.kioskSlide;
        let slide = ( this.state.kioskSlide + 1 < this.state.kioskMax ) ? this.state.kioskSlide + 1 : 0;
        let ck1 = this.state.screensArray[ prevSlide ].checkrp;
        let ck2 = this.state.screensArray[ slide ].checkrp;
        this.setState({
            kioskSlide : slide
        }, () => { this.setLayoutOnKiosk( ck1, ck2, slide ) } );
    }
    
    getDataModeKiok( response, wpid ) {
        clearInterval( this.state.kioskMode ); 
        this.setState({ 
            screensArray    : response.data.screens,
            currentWPID     : wpid,
            kioskMax        : response.data.screens.length,
            kioskMode       : setInterval( () => { this.setLayoutKiosk() } , 5000 ),
            viewLayoutKeyS1 : 0,
            viewLayoutKeyS2 : 0,
            isPlayBack      : true,
        });
    }

    getData( wpid ) {
        this.loadCount = 0;
        this.loadableCount = 1;
        this.setState({ 
            viewScreen1     : 'unactive',
            viewScreen2     : 'unactive',
            isHidden        : false,
        });

        getScreensInfo( wpid ).then( ( response ) => {
            if ( wpid === 'kiosk' ) {
                this.getDataModeKiok( response, wpid );
            } else {
                this.getDataModeRemote( response, wpid );
            }
        }).catch( ( error ) => { 
            this.setAPIError( error );
        });

    }

    
    refreshToken() {
        getWebSocketTokenDisplay().then( ( response ) => {   
            this.setState({ 
                WSToken     : response.data.token,
                webSocket   : getDisplaySocket( this, response.data.token ),
                webSocketR  : getRemoteSocket( this, response.data.token )
            });
        }).catch( ( error ) => { 
            this.setAPIError( error );
        });
    }

    setAPIError( error ) {
        this.setState({ APIerror : true, APIerrorInfo : error });
    }

    componentDidMount() {
        this.refreshToken(); 
        this.getData( 'kiosk' );
    }

    componentDidUpdate( prevProps, prevState ) {
        if ( prevState.mustReload === false && this.state.mustReload ) {
            setInterval( () => {
                this.setState( { countDown : this.state.countDown - 1 } )
            }, 1000 );
        }
    }

    setLayout( layout ) {
        var content = this.state.screensArray;
        if ( this.state.isReady ) {
            this.loadCount = 0;
            this.loadableCount = _.size( _.filter( _.get( content, `[${layout}].modules`, [] ), v => v.template === 'image' || v.template === 'slide' || v.template === 'video' || v.template === 'unsafe-html' ) )
            if ( this.state.currentScreen === 1 ) {
                this.setState({
                    viewLayoutKeyS2 : layout,
                    currentScreen   : 2,
                    isFetching      : true,
                    isReady         : false,
                });
                setTimeout(() => {
                    this.setState({
                        viewLayoutKeyS1 : -1,
                        isReady         : true,
                    });  
                }, 3500 );
            } else {
                this.setState({
                    viewLayoutKeyS1 : layout,
                    currentScreen   : 1,
                    isFetching      : true,
                    isReady         : false,
                });     
                setTimeout(() => {
                    this.setState({
                        viewLayoutKeyS2 : -1,
                        isReady         : true,
                    });  
                    if ( this.state.WSReady ) { }
                }, 3500 );
            }
        }
    }
    
    setIpadStatus() {
        if ( this.state.WSReady ) {
            this.setVideoBufferSatus( true );
            onLoadScreenSendMessage( this, this.state.iPadReady ); 
        }
    }
    
    onLoadScreen() {
        this.loadCount = this.loadCount + 1;
        if(this.loadCount === this.loadableCount) {
            if ( this.state.currentScreen === 1 ) {
                this.setState({
                    viewScreen1     : 'active',
                    viewScreen2     : 'unactive',
                    currentView     : 1,
                    isFetching      : false,
                    iPadReady       : true,
                }, () => { this.setIpadStatus() });    
            } else {
                this.setState({
                    viewScreen1     : 'unactive',
                    viewScreen2     : 'active',
                    currentView     : 2,
                    isFetching      : false,
                    iPadReady       : true
                }, () => { this.setIpadStatus() }); 
            }
        }
    }

    refreshPWASource() {
        this.refreshPage( false );
        forceReloadScreenSendMessage( this, false );  
        window.location.reload();
    }
    
    renderVersion() {

        var that = this;
        setTimeout( function() {
            that.refreshPWASource();
        }, 9800 );

        return(
            <div className={'new-version'}>
                <div className={'inner supersymetric'}>                
                    <p>New version available, reloading APP to get updates</p>
                    <p><em>Will reload in { this.state.countDown } seconds...</em></p>
                    <button onClick={ this.refreshPWASource }>Reload</button>
                </div>
            </div>
        );
    }

    render_connect_info() {
        return(
            <div className={ 'cnt-info' }>
                <ul>
                    <li><span>envirometName:</span> { setup.envirometName }</li>
                    <li><span>APIBaseUrl:</span> { setup.APIBaseUrl }</li>
                    <li><span>APIPrefix:</span> { setup.APIPrefix }</li>
                    <li><span>WSRemoteTokenUrl:</span> { setup.WSRemoteTokenUrl }</li>
                    <li><span>WSDsiplayTokenUrl:</span> { setup.WSDsiplayTokenUrl }</li>
                    <li><span>remoteSocket:</span> { setup.remoteSocket }</li>
                    <li><span>displaySocket:</span> { setup.displaySocket }</li>
                    <li><span>originWP:</span> { setup.originWP }</li>
                    <li><span>originCDN:</span> { setup.originCDN }</li>
                </ul>
            </div>
        )
    }

    setErrorPop( errCopy, addinfo ) {
        var that = this;
        setTimeout( function() {
            window.location.reload();
        }, 3000 );
        
        let addinfoCP = ( addinfo ) ? <p><em> { addinfo.toJSON().message } </em></p> : '';
        return(
            <div className={ 'error vertical-supersymetric' }>
                <h3>Error</h3>
                <p>{ errCopy }</p>
                { addinfoCP }
                <a href={ '/' }>{ 'Reloading' }</a>
            </div>
        )
    }
    
    renderScreenView( screensArray, key, viewScreen, scClass ) {
        
        let { isHidden, isPlayBack, slideState, webSocketR, WSReady } = this.state;
        var hiddeableHidden = ( isHidden ) ? 'overlayHidden' : '';
        
        return(
            <ScreenView 
                content={ screensArray[ key ] }
                addClass={ 'screens ' + scClass + ' ts ts-ease-in ts-slow ' + viewScreen }
                onLoadCall={ this.onLoadScreen } 
                onVideoReady={ this.setVideoIsReady }
                onVideoEnd={ this.setVideoIsEnd }
                onBuffer={ this.setVideoBufferSatus }
                hiddeableHidden={ hiddeableHidden } 
                isPlayBack={ isPlayBack } 
                slideState={ slideState }
                webSocketR={ webSocketR }
                WSReady={ WSReady }
            />
        )
    }
    
    
    render_regular_mode() {
        var { viewLayoutKeyS1, viewLayoutKeyS2, viewScreen1, viewScreen2, screensArray } = this.state;
        return( 
            <div>
                { this.renderScreenView( screensArray, viewLayoutKeyS1, viewScreen1, 'screen-one' ) }
                { this.renderScreenView( screensArray, viewLayoutKeyS2, viewScreen2, 'screen-two' ) }
            </div>
        )
    }

    render() {  

        var { dspConnectInfo, WSError, APIerror, APIerrorInfo, mustReload } = this.state;
        var version = ( mustReload ) ? this.renderVersion() : false;
        let connectInfo = ( dspConnectInfo ) ? this.render_connect_info() : '';
        let error = ( WSError ) ? this.setErrorPop( 'Connection to iPad is lost', false ) : false;
        let errorAPI = ( APIerror ) ? this.setErrorPop( 'Connection to data is lost', APIerrorInfo ) : false;
        let errorCont = ( errorAPI ) ? errorAPI : error;
        let content = ( errorCont ) ? errorCont : this.render_regular_mode();

        return(
            <div>
                { content }
                { version }
                { connectInfo }
            </div>
        )
    }
}

export default App;