import React, {Component} from 'react';
import './style.css';

class TrilineModule extends Component {

    render() {
        
        var { module } = this.props;
        
        return(
            <div className={ 'atom template-triline grad02 ' + this.props.addClass }>
                <div className={ 'copy-holder v-third' }>
                    <div className={ 'inner vertical-top' }>
                        <span className={ 'transitable relative fadein slidedown tag ts ts-ease-out ts-delay-slower ts-slow' }>{ module.tag }</span>
                    </div>
                </div>
                <div className={ 'copy-holder v-third' }>
                    <div className={ 'inner vertical-supersymetric' }>
                        <h1 className={ 'transitable relative fadein slidedown ts ts-ease-out ts-delay-slow ts-slow' }>{ module.title }</h1>
                    </div>
                </div>
                <div className={ 'copy-holder v-third' }>
                    <div className={ 'inner vertical-bottom' }>
                        <p className={ 'student transitable relative fadein slidedown ts ts-ease-out ts-delay ts-delay-slow' }>{ module.student }</p>
                        <p className={ 'grade transitable relative fadein slidedown ts ts-ease-out ts-delay-slow' }>{ module.grade }</p>
                    </div>
                </div>
            </div>
        )
    }
    
}
export default TrilineModule;



