import React, {Component} from 'react';
import './style.css';
import { ImageModule, VideoModule, EventModule, ProjectModule, DemoModule, TrilineModule, QuoteModule, SlideModule, UnsafeHtml } from '../../atoms';

class ScreenModule extends Component {

    render_inner_template( module, onLoadCall, slidePos, slideExpand, isPlayBack, onVideoReady, onVideoEnd, onBuffer ) {
        var moduleTemplate; 
        switch( module.template ) {
            case 'image'        : moduleTemplate = <ImageModule module={ module } onLoadCall={ onLoadCall }  />;   break;
            case 'video'        : moduleTemplate = <VideoModule module={ module } onLoadCall={ onLoadCall } isPlayBack={ isPlayBack } onVideoReady={ onVideoReady } onVideoEnd={ onVideoEnd } onBuffer={ onBuffer } />; break;
            case 'event'        : moduleTemplate = <EventModule module={ module } onLoadCall={ onLoadCall }  />;   break;
            case 'project'      : moduleTemplate = <ProjectModule module={ module } onLoadCall={ onLoadCall }  />; break;
            case 'demo'         : moduleTemplate = <DemoModule module={ module } onLoadCall={ onLoadCall }  />;    break;
            case 'triline'      : moduleTemplate = <TrilineModule module={ module } onLoadCall={ onLoadCall }  />; break;
            case 'quote'        : moduleTemplate = <QuoteModule module={ module } onLoadCall={ onLoadCall }  />;   break;
            case 'unsafe-html'  : moduleTemplate = <UnsafeHtml module={ module } onLoadCall={ onLoadCall }  />; break;
            case 'slide'        : moduleTemplate = <SlideModule module={ module } onLoadCall={ onLoadCall } slidePos={ slidePos } slideExpand={ slideExpand } />;   break;
            default             : moduleTemplate = <span></span>;
        }
        return moduleTemplate;
    }
    
    render() {
        
        var { hclass, wclass, trans, overlay, module, timming, onLoadCall, slidePos, slideExpand, isPlayBack, onVideoReady, onVideoEnd, onBuffer } = this.props;
        var overlayClass = ( overlay ) ? 'overlay ' + overlay : '';
        var timmingClass = ( timming ) ? timming : 'ts-ease-in ts-quick ts-delay';
        return(
            <div className = { 'module ts ' + timmingClass + ' ' + overlayClass + ' ' + hclass + ' ' + wclass + ' ' + trans }>
                <div className={ 'inner-module' }>
                    { this.render_inner_template( module, onLoadCall, slidePos, slideExpand, isPlayBack, onVideoReady, onVideoEnd, onBuffer ) } 
                </div>
            </div>
        )
    }
    
}
export default ScreenModule;
