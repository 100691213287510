import React, {Component} from 'react';
import './style.css';

class EventModule extends Component {

    getCloseLoop( module ) {
        var response = '';
        if ( module.closearr ) {
            response = module.closearr.map( function( closeLine, index  ) {
                return <strong key={ index }>{ closeLine }</strong>;
            });
        }
        return response;
    }

    render() {
        
        var { module } = this.props;
        var closeLoop = this.getCloseLoop( module );
        
        return(
            <div className={ 'atom template-event ' + this.props.addClass }>
                <div className={ 'copy-holder grad02' }>
                    <div className={ 'inner copy-holder v-half' }>
                        <span className={ 'tag-holder' }>
                            <span className={ 'transitable relative fadein slidedown tag ts ts-ease-out ts-delay-slowest ts-quick' }>{ module.tag }</span>
                        </span>
                        <span className={ 'date' }>
                            <span className={ 'transitable relative fadein slidedown month ts ts-ease-out ts-delay-slower ts-slow' }>{ module.month }</span>
                            <span className={ 'transitable relative fadein slidedown day ts ts-ease-out ts-delay-slower' }>{ module.day }</span>
                        </span>
                    </div>
                    <div className={ 'inner copy-holder at-bottom v-half' }>
                        <h1 className={ 'transitable relative fadein slidedown ts ts-ease-out ts-delay-slow ts-slow' }>{ module.title }</h1>
                        <p className={ 'big transitable fadein slidedown relative ts ts-ease-out ts-delay-slower' }>
                            { closeLoop }
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    
}
export default EventModule;
