import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { setup } from '../../../config/'

import './style.css';

class SlideModule extends Component {

    getsliderLoop( module, slidePos, slideExpand ) {
        var response = '';
        if ( module.slides ) {
            response = module.slides.map( function( single, index ) {
                
                var activeClass = ( slideExpand === slidePos ) ? 'unfold' : 'fold';
                var renderSubtit = ( single.subtitle ) ? <span className={ 'subtitle ts ts-slide-out' }>{ single.subtitle }</span> : false;
                var renderCourse = ( single.grade ) ? <span className={ 'grade ts ts-slide-out' }>{ single.grade }</span> : false;
                
                return (
                    <div className={ 'slide-box ' + activeClass } key={ index }>
                        <img src={ single.url.replace( setup.originWP, setup.originCDN ) } className={ 'ts ts-slide-out' } alt={ 'slide-' + index } />
                        <p className={ 'grad03 revert ts ts-slide-out' }>
                            <span className={ 'supersymetric' }>
                                <span className={ 'student ts ts-slide-out' }>{ single.student }</span>
                                { renderSubtit }
                                { renderCourse }                            
                            </span>
                        </p>
                    </div>
                );
            });
        }
        return response;
    }

    render() {
        var { module, onLoadCall, slidePos, slideExpand } = this.props;
        var sliderLoop = this.getsliderLoop( module, slidePos, slideExpand );
        return(
             <div className={ 'atom template-event' } onLoad={ onLoadCall }>
                <Carousel showArrows={ false } infiniteLoop={ true } axis={ 'vertical' } showStatus={ false } showThumbs={ false } showIndicators={ false } selectedItem={ slidePos } slideExpand={ slideExpand }>
                    { sliderLoop }
                </Carousel>
            </div>
        )
    }
    
}
export default SlideModule;
