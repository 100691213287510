import React, { Component } from 'react';
import { FullLayout, TwoColsLayout, EventLayout, ProjectLayout, DemoLayout, TricolLayout, QuoteLayout, FourColsLayout, SliderLayout, ASTwoColsLayout } from '../../organisms';
import './style.css';

//  unsafe-html

class ScreenView extends Component {

    render_layout_holder( props ) {
        var layoutClass = '';
        if ( props.content ) {
            switch( props.content.layout ) {
                case 'twocols-layout'       : layoutClass = <TwoColsLayout modules={ props.content.modules } { ...props } />;    break;
                case 'full-layout'          : layoutClass = <FullLayout modules={ props.content.modules } { ...props } />;       break;
                case 'event-layout'         : layoutClass = <EventLayout modules={ props.content.modules } { ...props } />;      break;
                case 'project-layout'       : layoutClass = <ProjectLayout modules={ props.content.modules } { ...props } />;    break;
                case 'demo-layout'          : layoutClass = <DemoLayout modules={ props.content.modules } { ...props } />;       break;
                case 'tricol-layout'        : layoutClass = <TricolLayout modules={ props.content.modules } { ...props } />;     break;
                case 'quote-layout'         : layoutClass = <QuoteLayout modules={ props.content.modules } { ...props } />;      break;
                case 'fourcols-layout'      : layoutClass = <FourColsLayout modules={ props.content.modules } { ...props } />;   break;
                case 'as2cols-layout'       : layoutClass = <ASTwoColsLayout modules={ props.content.modules } { ...props } />;  break;
                case 'slide-layout'         : layoutClass = <SliderLayout modules={ props.content.modules } { ...props } />;     break;
                case 'unsafe-html-layout'   : layoutClass = <FullLayout modules={ props.content.modules } { ...props } />;     break;
                default                     : layoutClass = <span></span>;
            }            
        }
        return layoutClass;
    } 

    render() {
        const { content, addClass, hiddeableHidden } = this.props;
        const layClass = ( content ) ? content.layout : 'loader';

        return(
            <div className={ 'screens-holder ' + layClass + ' ' + addClass + ' ' + hiddeableHidden } >
                { this.render_layout_holder( this.props ) }
            </div>
        )
    }
}

export default ScreenView;