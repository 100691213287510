import React, {Component} from 'react';
import './style.css';

class QuoteModule extends Component {
    
    render() {
        
        var { module } = this.props;

        return(
            <div className={ 'atom template-quote grad02 ' + this.props.addClass }>
                <div className={ 'copy-holder center' }>
                    <div className={ 'inner vertical-supersymetric' }>
                        <div className={ 'transitable relative fadein xs-slideup ts ts-ease-out ts-delay-slower' } >
                            <blockquote>
                                <span>{ '"' }</span> { module.quote } <span>{ '"' }</span>
                            </blockquote>
                            <cite className={ 'transitable relative fadein xs-slidedown ts ts-ease-out ts-delay-slow' }>
                                { module.student }
                            </cite>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
}
export default QuoteModule;



