import React, {Component} from 'react';
import { ScreenModule } from '../../molecules';
import './style.css';

class ASTwoColsLayout extends Component {

    render() {
        
        var { modules } = this.props;
        var KenBurnsClassA = ( modules[0].kenburns ) ? ' image-kenburns' : '';
        var KenBurnsClassB = ( modules[1].kenburns ) ? ' image-kenburns-slow' : '';

        return(
            <div className={ 'layout-holder asim-layout-two-cols' }>
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-3-4' } trans={ 'fade top zoomin' + KenBurnsClassA } module={ modules[0] } { ...this.props } />
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-1-4' } trans={ 'fade top' + KenBurnsClassB } module={ modules[1] } { ...this.props } />
            </div>
        )
    }
    
}
export default ASTwoColsLayout;
