import React, {Component} from 'react';
import { ScreenModule } from '../../molecules';
import './style.css';

class SliderLayout extends Component {

    render() {
        
        var { modules, onLoadCall, slideState } = this.props;
        var { expandSlide1, expandSlide2, expandSlide3, expandSlide4, slider1Pos, slider2Pos, slider3Pos, slider4Pos } = slideState;

        return(
            
            <div className={ 'layout-holder layout-four-cols' }>
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-1-4' } trans={ 'fade top' } module={ modules[0] } onLoadCall={ onLoadCall } slidePos={ slider1Pos } slideExpand={ expandSlide1 } />
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-1-4' } trans={ 'fade top' } module={ modules[1] } onLoadCall={ onLoadCall } slidePos={ slider2Pos } slideExpand={ expandSlide2 } />
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-1-4' } trans={ 'fade top' } module={ modules[2] } onLoadCall={ onLoadCall } slidePos={ slider3Pos } slideExpand={ expandSlide3 } />
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-1-4' } trans={ 'fade top' } module={ modules[3] } onLoadCall={ onLoadCall } slidePos={ slider4Pos } slideExpand={ expandSlide4 } />
            </div>
        )
    }
    
}

export default SliderLayout;