import React, {Component} from 'react';
import { ScreenModule } from '../../molecules';
import './style.css';

class DemoLayout extends Component {

    render() {
        
        var { modules } = this.props;
        var KenBurnsClassA = ( modules[0].kenburns ) ? ' image-kenburns' : '';
        
        return(
            <div className={ 'layout-holder layout-demo' }>
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-2-4' } trans={ 'zoomout right fade' + KenBurnsClassA } module={ modules[0] }  { ...this.props } />
                <ScreenModule overlay={ 'l-3 t-1' } hclass={ 'h-3-3' } wclass={ 'w-2-4' } trans={ 'right fade' } module={ modules[1] } { ...this.props } />
            </div>
        )
    }
    
}
export default DemoLayout;
