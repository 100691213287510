import React, {Component} from 'react';
import ReactPlayer from 'react-player'
import { setup } from '../../../config/'

import './style.css';

class VideoModule extends Component {
    
    constructor( props ) {
        super( props );
        this.state = {
            buffering   : true,  
            isLoadCB    : false,        
        }
        this.videoShouldBeReady = this.videoShouldBeReady.bind( this )
        this.videoIsBuffering = this.videoIsBuffering.bind( this )
        this.videoIsDoneBuffer = this.videoIsDoneBuffer.bind( this )
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.onLoadCall(); 
        }, 3000 );
    }
    
    videoShouldBeReady() {
        setTimeout(() => {
            this.setState({ isLoadCB : true }, () => { this.props.onVideoReady() });
        }, 4500 );
    }
    
    videoIsBuffering() {
        this.setState({ buffering : true }, () => { this.props.onBuffer( this.state.buffering ) } )
    }

    videoIsDoneBuffer() {
        this.setState({ buffering : false }, () => { this.props.onBuffer( this.state.buffering ) } )
    }
    
    renderPoster( poster ) {
        var posterUrl = poster.replace( setup.originWP, setup.originCDN );
        return( 
            <figure>
                <img src={ posterUrl } alt={ posterUrl } onLoad={ this.props.onLoadCall } />
            </figure>
        )
    }

    render() {        

        var { module, onLoadCall, isPlayBack } = this.props;
        var renderUrl = ( module.url ) ? module.url.replace( setup.originWP, setup.originCDN ) : false;
        var renderPoster = ( module.poster ) ? this.renderPoster( module.poster ) : false;
        var bufferAlert = ( ( this.state.buffering && isPlayBack ) || ( !this.state.isLoadCB && !isPlayBack ) ) ? <p className={ 'buffer' }><span></span></p> : '';
        return(
            <div className={ 'atom atom-video' } onLoad={ onLoadCall }>
                { renderPoster }
                <ReactPlayer 
                    url={ renderUrl } 
                    type={ 'video/mp4' } 
                    controls={ false } 
                    playing={ isPlayBack } 
                    onReady={ this.videoShouldBeReady } 
                    onEnded={ this.props.onVideoEnd }
                    onBuffer={ this.videoIsBuffering }
                    onBufferEnd={ this.videoIsDoneBuffer }
                />
                { bufferAlert }
            </div>
        )
    }
    
}
export default VideoModule;


