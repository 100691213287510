import React, {Component} from 'react';
import './style.css';

class ProjectModule extends Component {

    getStudentsLoop( module ) {
        var response = '';
        if ( module.students ) {
            response = module.students.map( function( student, index  ) {
                return <li key={ index }>{ student }</li>;
            });
        }
        return response;
    }
    
    render() {
        
        var { module } = this.props;
        var studentsList = this.getStudentsLoop( module );

        return(
            <div className={ 'atom template-project grad02 ' + this.props.addClass }>
                <div className={ 'copy-holder h-half' }>
                    <div className={ 'inner vertical-supersymetric' }>
                        <h2 className={ 'transitable relative fadein xs-slidedown ts ts-ease-out ts-delay-slower' }>
                            <span className={ 'transitable ts ts-ease-out ts-delay-slowest relative' }>{ module.tag }</span>
                            { module.title }
                        </h2>
                        <p className={ 'transitable relative fadein xs-slidedown ts ts-ease-out ts-delay-slow'} >{ module.copy }</p>
                    </div>
                </div>
                <div className={ 'copy-holder h-half' }>
                    <div className={ 'inner vertical-supersymetric' }>
                        <h3 className={ 'transitable relative fadein xs-slidedown ts ts-ease-out ts-delay-slower' }>Students</h3>
                        <ul className={ 'transitable relative fadein xs-slidedown ts ts-ease-out ts-delay-slow ts-slow' }>{ studentsList }</ul>
                    </div>
                </div>
            </div>
        )
    }
    
}
export default ProjectModule;



