import React, {Component} from 'react';
import './style.css';

class UnsafeHtml extends Component {

    componentDidMount() {
        setTimeout(() => {
            this.props.onLoadCall(); 
        }, 500 );
    }

    render() {
        var { module, onLoadCall } = this.props;
        return(
            <div className={ 'unsafe-html' } onLoad={ onLoadCall }>
                <iframe src={ module.source } title={ 'iframe-title' }></iframe>
            </div>
        )
    }
    
}
export default UnsafeHtml;
