import React, {Component} from 'react';
import './style.css';

class DemoModule extends Component {

    getCloseLoop( module ) {
        var response = '';
        if ( module.close ) {
            response = module.close.map( function( closeLine, index  ) {
                return <strong key={ index }>{ closeLine }</strong>;
            });
        }
        return response;
    }
    
    render() {

        
        var { module } = this.props;
        var closeLoop = this.getCloseLoop( module );
        
        return(
            <div className={ 'atom template-demo grad02 ' + this.props.addClass }>
                <div className={ 'copy-holder h-half' }>
                    <div className={ 'copy-holder v-half' }>
                        <div className={ 'inner' }>
                            <span className={ 'tag-holder' }>
                                <span className={ 'tag transitable relative fadein slidedown ts ts-ease-out ts-delay-slowest ts-quick' }>{ module.tag }</span>
                            </span>
                            <span className={ 'date' }>
                                <span className={ 'month transitable relative fadein slidedown ts ts-ease-out ts-delay-slower ts-slow' }>{ module.month }</span>
                                <span className={ 'day transitable relative fadein slidedown ts ts-ease-out ts-delay-slower' }>{ module.day }</span>
                            </span>
                        </div>
                    </div>
                    <div className={ 'copy-holder v-half' }>
                        <div className={ 'inner' }>
                            <h1 className={ 'transitable relative fadein slidedown ts ts-ease-out ts-delay-slow ts-slow' }>
                                { module.title }
                            </h1>
                        </div>
                    </div>
                </div>
                <div className={ 'copy-holder h-half' }>
                    <div className={ 'copy-holder v-half' }> </div>
                    <div className={ 'copy-holder v-half' }>
                        <div className={ 'inner' }>
                            <p className={ 'big transitable fadein slidedown relative ts ts-ease-out ts-delay-slower' }>
                                { closeLoop }
                            </p>
                            <p className={ 'transitable fadein slidedown relative ts ts-ease-out ts-delay-slow' }>
                                { module.copy }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
}
export default DemoModule;



