import { get, API_PREFIX, REMOTE_TOKEN, DISPLAY_TOKEN } from './utils'

export function getScreensInfo( wpid ) {
    let url;
    switch ( wpid ) {
        case 'kiosk'    : url = `${API_PREFIX}/get-screens/kiosk`; break;
        case 'mashup'   : url = `${API_PREFIX}/get-mashup/screens`; break;
        default         : url = `${API_PREFIX}/get-project/${wpid}/screens`; break;
    }
    return get( url );
}

export function getWebSocketTokenRemote() {
    const url = REMOTE_TOKEN;
    return get( url );
}

export function getWebSocketTokenDisplay() {
    const url = DISPLAY_TOKEN;
    return get( url );
}