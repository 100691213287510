import React, {Component} from 'react';
import { ScreenModule } from '../../molecules';
import './style.css';

class FourColsLayout extends Component {

    render() {
        
        var { modules } = this.props;
        var KenBurnsClassA = ( modules[0].kenburns ) ? ' image-kenburns-slow' : '';
        var KenBurnsClassB = ( modules[1].kenburns ) ? ' image-kenburns' : '';
        var KenBurnsClassC = ( modules[2].kenburns ) ? ' image-kenburns-slow' : '';
        var KenBurnsClassD = ( modules[3].kenburns ) ? ' image-kenburns' : '';
        
        return(
            <div className={ 'layout-holder layout-four-cols' }>
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-1-4' } trans={ 'fade top' + KenBurnsClassA } timming={ 'ts-ease-out ts-delay' } module={ modules[0] } { ...this.props } />
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-1-4' } trans={ 'fade top' + KenBurnsClassB } timming={ 'ts-ease-out ts-slow ts-delay' } module={ modules[1] } { ...this.props } />
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-1-4' } trans={ 'fade top' + KenBurnsClassC } timming={ 'ts-ease-out ts-slower ts-delay' } module={ modules[2] } { ...this.props } />
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-1-4' } trans={ 'fade top' + KenBurnsClassD } timming={ 'ts-ease-out ts-slowest ts-delay' } module={ modules[3] } { ...this.props } />
            </div>
        )
    }
    
}
export default FourColsLayout;
