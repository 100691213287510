import React, {Component} from 'react';
import { ScreenModule } from '../../molecules';
import './style.css';

class TricolLayout extends Component {

    render() {
        
        var { modules } = this.props;
        var KenBurnsClassA = ( modules[0].kenburns ) ? ' image-kenburns' : '';
        var KenBurnsClassB = ( modules[2].kenburns ) ? ' image-kenburns-slow' : '';
        return(
            <div className={ 'layout-holder layout-tricol' }>
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-3-4' } trans={ 'zoomout fade' + KenBurnsClassA } module={ modules[0] } { ...this.props } />
                <ScreenModule overlay={ 'l-3 t-1' } hclass={ 'h-3-3' } wclass={ 'w-1-4' } trans={ 'right fade' } module={ modules[1] } { ...this.props } />
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-1-4' } trans={ 'zoomout fade' + KenBurnsClassB } module={ modules[2] } { ...this.props } />
            </div>
        )
    }
    
}
export default TricolLayout;
