import React, {Component} from 'react';
import { ScreenModule } from '../../molecules';
import './style.css';

class QuoteLayout extends Component {

    render() {
        
        var { modules } = this.props;
        var KenBurnsClassA = ( modules[0].kenburns ) ? ' image-kenburns' : '';
        var KenBurnsClassB = ( modules[1].kenburns ) ? ' image-kenburns-slow' : '';
        
        return(
            <div className={ 'layout-holder layout-event' }>
                <ScreenModule hclass={ 'h-3-3' } wclass={ 'w-3-4' } trans={ 'zoomout fade' + KenBurnsClassA } module={ modules[0] } { ...this.props } />
                <ScreenModule overlay={ 'l-3 t-3' } hclass={ 'h-1-3' } wclass={ 'w-2-4' } trans={ 'bottom fade' } module={ modules[2] } { ...this.props } />
                <ScreenModule hclass={ 'h-2-3' } wclass={ 'w-1-4' } trans={ 'top fade' + KenBurnsClassB } module={ modules[1] } { ...this.props } />

            </div>
        )
    }
    
}
export default QuoteLayout;
